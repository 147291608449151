exports.components = {
  "component---src-templates-academic-program-page-js": () => import("./../../../src/templates/academic-program-page.js" /* webpackChunkName: "component---src-templates-academic-program-page-js" */),
  "component---src-templates-academic-programs-js": () => import("./../../../src/templates/academic-programs.js" /* webpackChunkName: "component---src-templates-academic-programs-js" */),
  "component---src-templates-apply-admission-counselors-js": () => import("./../../../src/templates/apply/admission-counselors.js" /* webpackChunkName: "component---src-templates-apply-admission-counselors-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-page-up-js": () => import("./../../../src/templates/page-up.js" /* webpackChunkName: "component---src-templates-page-up-js" */),
  "component---src-templates-rollinslife-js": () => import("./../../../src/templates/rollinslife.js" /* webpackChunkName: "component---src-templates-rollinslife-js" */),
  "component---src-templates-sanity-page-js": () => import("./../../../src/templates/sanity-page.js" /* webpackChunkName: "component---src-templates-sanity-page-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */)
}

